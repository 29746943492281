<template>
  <div class="criar-container">
    
    <!-- -->
      <section class="container-fluid" style="background:#F9F7EA;">

        <div class="row">
            
            <div class="col-sm-12 px-lg-5">
            <br>
                      <h3 class="txt-titulo-roxo bold" >Crie sua receita</h3>
                      <p class="txt-paragrafo-roxo">
Para criar sua receita, siga o modelo abaixo e insira suas informações.  
Se precisar interromper a inserção para retomá-la depois, utilize o botão “Salvar Rascunho”. 
Ao final, clique em “Enviar para análise”. <br>

Sua receita ficará guardada nessa seção, mesmo que, por algum motivo, não seja publicada no site.<br>
Campos com *(asterisco) são obrigatórios.

<br><br>
<strong>Importante:</strong> sabemos que o uso do nosso formulário de inserção de receitas pode gerar algum desconforto pelo trabalho adicional que ele gera, mas é muito importante que ele seja seguido à risca, pois grande parte do nosso sucesso diz respeito exatamente a esse nosso modelo de apresentação de receitas.</p><br>

<div class="alert alert-danger" role="alert" v-if="status == 'Negado'">
  <strong>Receita Negada Pela equipe Tarimba!</strong><br>
  {{motivo}}
</div>

<div class="alert alert-success" role="alert" v-if="status == 'Publicado'">
  <strong>Obrigado! Sua receita foi aprovada e publicada pela equipe Tarimba!</strong>
</div>
<br>


                <div class="row">
                  <div class="col-sm-2">
                    <img v-if="foto != null && foto != '' && foto.length > 5" :src="foto" width="250" height="auto" ref="imgReceita" class="img-fluid" />
                      <img v-else src="/img/no-image.png"  class="img-fluid" />
                 </div>
                  <div class="col-sm-5">
                    <div class="form-group">
                      <label class="label-receitas ">Mande sua foto desta receita! (Recomendado: 300px x 300px)</label>
                     <input type="file" name="imagem" ref="foto" class="form-control input-cadastro-receita" @change="onFileSelected" />
                     </div>

                      <div class="form-group">
                        <label class="label-receitas">Nome da receita *</label>
                        <input v-model="nome" class="form-control input-cadastro-receita" name="nome" placeholder="Nome" type="text"/>
                    </div>   

                 </div>
               </div>

<hr>

<h3 class="txt-titulo-roxo bold" >Detalhes da receita</h3>

<!-- -->
<div class="row">
   <div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Categoria *</label>
          <select v-model="categoria" name="categoria" id="categoria" class="input form-control input-cadastro-receita" required>
            <option value="">Selecione</option>
              <option value="Entradas">Entradas</option>
              <option value="Sopas, Cremes e Caldinhos">Sopas, Cremes e Caldinhos</option>
              <option value="Aperitivos, Comidinhas e Lanches">Aperitivos, Comidinhas e Lanches</option>
              <option value="Pratos Principais">Pratos Principais</option>
              <option value="Acompanhamentos">Acompanhamentos</option>
              <option value="Sobremesas">Sobremesas</option>
              <option value="Bolos, Doces e Pães">Bolos, Doces e Pães</option>
              <option value="Molhos e Caldos Clássicos">Molhos e Caldos Clássicos</option>

          </select>
          </div>
</div>


<div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Custo *</label>
          <select v-model="custo" name="custo" id="custo" class="input form-control input-cadastro-receita" required>
            <option value="">Selecione</option>
              <option value="$">$</option>
              <option value="$$">$$</option>
              <option value="$$$">$$$</option>
              <option value="$$$$">$$$$</option>
              <option value="$$$$$">$$$$$</option>
             

          </select>
          </div>
</div>

<div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Dificuldade *</label>
          <select v-model="dificuldade" name="dificuldade" id="dificuldade" class="input form-control input-cadastro-receita" required>
            <option value="">Selecione</option>
              <option value="Fácil">Fácil</option>
              <option value="Média">Média</option>
              <option value="Difícil">Difícil</option>

          </select>
          </div>
</div>

<div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Rendimento *</label>
          <div class="input-group">
            <input v-model="rendimento" type="number" name="rendimento" min="0" class="input form-control input-cadastro-receita" required value="" />
            <div class="input-group-append"> <span class="input-group-text input-cadastro-receita">Pessoas</span></div>
          </div>
          </div>
</div>


<div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Tempo de preparo *</label>
          <div class="input-group">
            <input v-model="tempo" type="number" name="tempo" class="input form-control input-cadastro-receita" required value="" />
             <div class="input-group-append"> <span class="input-group-text input-cadastro-receita">Min</span></div>
          </div>
          </div>
</div>

<div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Dieta</label>
          <select v-model="dieta" name="dieta" id="dieta" class="input form-control input-cadastro-receita">
            <option value="">Selecione</option>
            <option v-for="(item,index) in dietas" :key="'dieta'+index"  :value="item.nome">{{item.nome}}</option>
                        </select>
          </div>
</div>

<div class="col-sm-3">
        <div class="form-group">
          <label class="label-receitas ">Exige Pré preparo *</label>
          <select v-model="preparo" name="preparo" class="input form-control input-cadastro-receita" required>
              <option value="Não">Não</option>
              <option value="Sim">Sim</option>
          </select>
          </div>
</div>


</div>
<hr>
<h3 class="txt-titulo-roxo bold" >Utensílios *</h3>

<AutocompleteCustom :resultados.sync="utensiliosMatches" :nome.sync="utensilioSelecionado" @pronto="addUtensilio" url="utensilios" classe="autocompleteUtensilio"></AutocompleteCustom>
 <br>
 <div class="row" v-for="(item,index) in utensilios" :key="'utensilio'+index">
   <div class="col-sm-2">
    <button style="margin-right:3px;margin-bottom:5px;background:#764573 !important;border-color:#764573;" type="button" class="btn btn-sm btn-primary"  @click="removerUtensilio(index)">
                    {{item.nome}} <span class="badge badge-light">X</span>
                  </button>
   </div>
    <div class="col-sm-8">
      <small v-if="item.novo == 'Sim'"><i>Esse item não existe em nosso banco de dados. Providenciaremos a sua inclusão na aprovação da sua receita.</i></small>
   </div>
 </div>



 <hr>
<h3 class="txt-titulo-roxo bold" >Equipamentos *</h3>

<AutocompleteCustom :resultados.sync="equipamentosMatches" :nome.sync="equipamentoSelecionado" @pronto="addEquipamento" url="equipamentos" classe="autocompleteEquipamento"></AutocompleteCustom>
 <br>
 <div class="row" v-for="(item,index) in equipamentos" :key="'equipamento'+index">
   <div class="col-sm-2">
    <button style="margin-right:3px;margin-bottom:5px;background:#764573 !important;border-color:#764573;" type="button" class="btn btn-sm btn-primary"  @click="removerEquipamento(index)">
                    {{item.nome}} <span class="badge badge-light">X</span>
                  </button>
   </div>
    <div class="col-sm-8">
      <small v-if="item.novo == 'Sim'"><i>Esse item não existe em nosso banco de dados. Providenciaremos a sua inclusão na aprovação da sua receita.</i></small>
   </div>
 </div>


 <hr>
<h3 class="txt-titulo-roxo bold" >Ingredientes *</h3>

<AutocompleteCustom v-if="opcoesIngredienteAberta == false" :resultados.sync="ingredientesMatches" :nome.sync="ingredienteSelecionado" @pronto="addIngrediente" url="ingredientes" classe="autocompleteIngredientes"></AutocompleteCustom>
 
<div class="row" v-if="opcoesIngredienteAberta">
  <div class="col-sm-3">
   <div class="form-group">
        <label class="label-receitas">Ingrediente</label>
           <input class="form-control input-cadastro-receita" v-model="ingredienteSelecionado"  name="ingrediente" readonly placeholder="" type="text"/>
      </div>  
  </div> 


 <div class="col-sm-3">
   <div class="form-group">
        <label class="label-receitas">Quantidade</label>
           <input v-model="quantidade" v-mask="['#','#.#','##.#','###.#','####.#']" class="form-control input-cadastro-receita" name="quantidade" placeholder="" type="text"/>
      </div>  
  </div> 

 <div class="col-sm-3">
                    <div class="form-group">
                      <label class="label-receitas">Unidade de Medida</label>
                      <select v-model="medida" name="unidade" id="unidade" class="input form-control input-cadastro-receita">
                        <option value="">Selecione</option>
                          <option value="Unidade">Unidade</option>
                          <option value="Quilograma">Quilograma</option>
                          <option value="Grama">Grama</option>
                          <option value="Litro">Litro</option>
                          <option value="Decilitro">Decilitro</option>
                          <option value="Centilitro">Centilitro</option>
                          <option value="Mililitro">Mililitro</option>
                          <option value="Xícara">Xícara</option>
                          <option value="Xícara de café">Xícara de café</option>
                          <option value="Xícara de chá">Xícara de chá</option>
                          <option value="Colher de café">Colher de café</option>
                          <option value="Colher de chá">Colher de chá</option>
                          <option value="Colher de sobremesa">Colher de sobremesa</option>
                          <option value="Colher de sopa">Colher de sopa</option>
                          <option value="Copo">Copo</option>
                          <option value="Pacote">Pacote</option>
                          <option value="Frasco">Frasco</option>
                          <option value="Lata">Lata</option>
                          <option value="Fatia">Fatia</option>
                          <option value="Maço">Maço</option>
                          <option value="Peça">Peça</option>
                          <option value="Pedaço">Pedaço</option>
                           <option value="Bandeja">Bandeja</option>
                           <option value="Envelope">Envelope</option>
                           <option value="Folha">Folha</option>
                           <option value="Haste">Haste</option>
                           <option value="Ramo">Ramo</option>
                           <option value="Talo">Talo</option>
                           <option value="Vidro">Vidro</option>
                          <option value="Quanto bastar">Quanto bastar</option>

                         
						
                      </select>
											</div>
            </div>


<div class="col-sm-6">
   <div class="form-group">
        <label class="label-receitas">Observação</label>
           <textarea v-model="obs" class="form-control input-cadastro-receita" name="obs" placeholder="" type="text"></textarea>
      </div>  
  </div> 

  <div class="col-sm-6">
   <button style="background:#764573 !important;border-color:#764573;margin-top:5%;margin-right:5px;" type="button" class="btn btn-sm btn-primary bold"  @click="salvarIngrediente">SALVAR</button>
  <button style="background:#764573 !important;border-color:#764573;margin-top:5%;margin-right:5px;" type="button" class="btn btn-sm btn-primary bold"  @click="cancelar">CANCELAR</button>
  <button style="background:#764573 !important;border-color:#764573;margin-top:5%;" type="button" class="btn btn-sm btn-primary bold" v-if="edicaoIngrediente"  @click="excluirIngrediente">EXCLUIR</button>
  </div> 

 

</div>
<br>
<div class="row" v-for="(item,index) in ingredientes" :key="'ingrediente'+index">
   <div class="col-sm-4">
    <button @click="editarIngrediente(index)" style="margin-right:3px;margin-bottom:5px;background:#764573 !important;border-color:#764573;" type="button" class="btn btn-sm btn-primary bold">
                    {{item.qtd.replace('.',',') + ' ' + item.medida+ ' - ' + item.nome}} <span class="badge badge-light">EDITAR</span>
                  </button>
   </div>
    <div class="col-sm-8">
      <small v-if="item.novo == 'Sim'"><i>Esse item não existe em nosso banco de dados. Providenciaremos a sua inclusão na aprovação da sua receita.</i></small>
   </div>
 </div>
<!-- -->

<hr>
<h3 class="txt-titulo-roxo bold" >Modo de preparo</h3>

 <div class="form-group">
              <label class="label-receitas " for="">Passo 1 *</label>
              <textarea v-model="passo1" name="passo1" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

            <div class="form-group">
              <label class="label-receitas " for="">Passo 2 *</label>
              <textarea v-model="passo2" name="passo2" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

            <div class="form-group">
              <label class="label-receitas " for="">Passo 3 *</label>
              <textarea v-model="passo3" name="passo3" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

            <div class="form-group">
              <label class="label-receitas " for="">Passo 4 *</label>
              <textarea v-model="passo4" name="passo4" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

            <div class="form-group">
              <label class="label-receitas " for="">Passo 5</label>
              <textarea v-model="passo5" name="passo5" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500"></textarea>
            </div>

            <div class="form-group">
              <label class="label-receitas " for="">Passo 6</label>
              <textarea v-model="passo6" name="passo6" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500"></textarea>
            </div>


            <hr>
           <h3 class="txt-titulo-roxo bold" >Dicas que podem melhorar sua receita</h3><br>

            <div class="form-group">
              <label class="label-receitas " for="">Dicas</label>
              <textarea v-model="sugestao" name="sugestao" spellcheck="true" class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

 <hr>
           <h3 class="txt-titulo-roxo bold" >Sugestões de Acompanhamento</h3><br>
             <div class="form-group">
              <label class="label-receitas " for="">Sugestões</label>
              <textarea v-model="acompanhamento" name="acompanhamento" spellcheck="true" class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

            <hr>
           <h3 class="txt-titulo-roxo bold" >Pontos de Atenção</h3><br>

            <div class="form-group">
              <label class="label-receitas " for="">Pontos</label>
              <textarea v-model="pontos" name="pontos" spellcheck="true"  class="form-control input-cadastro-receita" maxlength="500" ></textarea>
            </div>

            <hr>
            <h3 v-if="showTerminologia" class="txt-titulo-roxo bold" >Terminologia técnica</h3><br>

            <div v-if="showTerminologia" class="form-group">
              <label class="label-receitas " for="">Termos</label>
              <textarea v-model="termos" name="termos" spellcheck="true" class="form-control input-cadastro-receita"  maxlength="500" ></textarea>
            </div>

              <div class="d-flex justify-content-center" >
                    <div  class="spinner-border" role="status" v-show="carregar">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>

             <button v-if="carregar == false && status == 'Rascunho'" style="background:#764573 !important;border-color:#764573;margin-right:5px;" type="button" class="btn btn-xl btn-primary bold bt-mobile-full mb-1"  @click="rascunho">SALVAR RASCUNHO</button>
             <button v-if="carregar == false" style="background:#764573 !important;border-color:#764573;margin-right:5px;" type="button" class="btn btn-xl btn-primary bold bt-mobile-full mb-1"  @click="voltar">VOLTAR</button>
             <button v-if="carregar == false && status == 'Rascunho' || status == 'Negado'" style="background:#764573 !important;border-color:#764573;" type="button" class="btn btn-xl  btn-primary bold float-right bt-mobile-full mb-1"  @click="analise">ENVIAR PARA ANÁLISE</button>
        
<br><br>
          </div>
                  
       </div>
                  
      </section>
<!-- -->


 <div class="modal" id="modal-receita" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações importantes!</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{texto}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>





  </div>
</template>

<style>
.carregador{visibility: hidden;}
.txt-titulo-roxo{color:#400041;}
.label-receitas{font-size:0.9em;color:#400041;}
.input-cadastro-receita{font-size:0.9em !important;border-color:#400041;color:#400041;}
</style>

<script>
import servidor from '@/servidor'
import AutocompleteCustom from '@/components/AutocompleteCustom'

export default {
  name: 'CriarReceita',
  metaInfo: {
    title: 'Criar receita - Tarimba na cozinha'
  },
  components: { AutocompleteCustom },
  data(){
    return {
      dietas:[],
      utensilioSelecionado:'',
      utensiliosMatches:[],
      utensilios:[],
      equipamentoSelecionado:'',
      equipamentosMatches:[],
      equipamentos:[],
      ingredienteSelecionado:'',
      ingredientesMatches:[],
      ingredientes:[],
      quantidade:0,
      medida:'',
      obs:'',
      ingredienteNovo:'Não',
      posicao:0,
      edicaoIngrediente:false,
      opcoesIngredienteAberta:false,
      indiceEdicao:0,
      texto:'',
      nome:'',
      selectedImage:null,
      categoria:'',
      custo:'',
      dificuldade:'',
      rendimento:1,
      tempo:1,
      dieta:'',
      preparo:'Não',
      passo1:'',
      passo2:'',
      passo3:'',
      passo4:'',
      passo5:'',
      passo6:'',
      sugestao:'',
      termos:'',
      pontos:"",
      motivo:"",
      acompanhamento:'',
      carregar:false,
      id:0,
      acao:'',
      status:'Rascunho',
      foto:'',
      showTerminologia:false

    }
  },
methods:{
  voltar(){
    window.history.back();
  },
   getDietas() {
      return new Promise((resolve) => {
        fetch(servidor+'autocomplete/dietas')
          .then((response) => response.json())
          .then((data) => {
            resolve(data)
          })
      });
    },
  removerUtensilio(index){
      this.utensilios.splice(index,1);
    },
    removerEquipamento(index){
      this.equipamentos.splice(index,1);
    },
    addUtensilio(item){
       if(this.utensilioSelecionado.length > 0){
        if(this.utensiliosMatches.indexOf(this.utensilioSelecionado) == -1){
          this.utensilios.push({nome:item,novo:'Sim'});
        }else{
          this.utensilios.push({nome:item,novo:'Não'});
        }
      }
    },
     addEquipamento(item){
       if(this.equipamentoSelecionado.length > 0){
        if(this.equipamentosMatches.indexOf(this.equipamentoSelecionado) == -1){
          this.equipamentos.push({nome:item,novo:'Sim'});
        }else{
          this.equipamentos.push({nome:item,novo:'Não'});
        }
      }
    },
    addIngrediente(){
      if(this.ingredienteSelecionado.length > 0){
        if(this.ingredientesMatches.indexOf(this.ingredienteSelecionado) == -1){
          this.ingredienteNovo = 'Sim';
        }else{
           this.ingredienteNovo = 'Não';
        }
        this.opcoesIngredienteAberta = true;
      }
    },
    
    salvarIngrediente(){
     
      if(this.medida == '' || this.quantidade.length == 0){this.abrirModal('Quantidade e Unidade de Medida são obrigatórias!');return;}
      if(this.edicaoIngrediente){
        this.ingredientes[this.indiceEdicao] = {nome:this.ingredienteSelecionado,novo: this.ingredienteNovo,qtd:this.quantidade.toString(),medida:this.medida,obs:this.obs,posicao:this.posicao};
      }else{
        this.ingredientes.push({nome:this.ingredienteSelecionado,novo: this.ingredienteNovo,qtd:this.quantidade.toString(),medida:this.medida,obs:this.obs,posicao:this.ingredientes.length});
      }
       
        this.opcoesIngredienteAberta = false;
        this.ingredienteSelecionado = '';
        this.quantidade = 0;
        this.medida = '';
        this.obs = '';
        this.ingredienteNovo = 'Não';
        this.edicaoIngrediente = false;
    },
    editarIngrediente(index){
        this.opcoesIngredienteAberta = true;
        this.ingredienteSelecionado =  this.ingredientes[index].nome;
        this.quantidade =  this.ingredientes[index].qtd;
        this.medida =  this.ingredientes[index].medida;
        this.obs =  this.ingredientes[index].obs;
        this.ingredienteNovo =  this.ingredientes[index].novo;
        this.posicao =  this.ingredientes[index].posicao;
        this.edicaoIngrediente = true;
        this.indiceEdicao = index;

    },
    cancelar(){
        this.opcoesIngredienteAberta = false;
        this.ingredienteSelecionado = '';
        this.quantidade = 0;
        this.medida = '';
        this.obs = '';
        this.ingredienteNovo = 'Não';
        this.edicaoIngrediente = false;
    },
    excluirIngrediente(){
      this.ingredientes.splice(this.indiceEdicao,1);
      this.cancelar();
    },
    onFileSelected(){
      this.selectedFile = event.target.files[0];
    },
    abrirModal(msg){
    this.texto = msg;
    window.jQuery('#modal-receita').modal('show');
   },
   rascunho(){
     if(this.nome.length < 4){this.abrirModal("Nome da receita deve conter pelo menos 4 caracteres!");return;}
     if(this.categoria.length < 1 || this.custo.length < 1 || this.dificuldade.length < 1 || this.tempo < 1 || this.rendimento < 1){this.abrirModal("Categoria, Custo, Dificuldade, Tempo e Rendimento são obrigatórios!");return;}
     if(this.status != 'Publicado'){this.status = "Rascunho";}
     this.enviar();
   },
   analise(){
     if(this.nome.length < 4){this.abrirModal("Nome da receita deve conter pelo menos 4 caracteres!");return;}
     if(this.categoria.length < 1 || this.custo.length < 1 || this.dificuldade.length < 1 || this.tempo < 1 || this.rendimento < 1){this.abrirModal("Categoria, Custo, Dificuldade, Tempo e Rendimento são obrigatórios!");return;}
     if(this.utensilios.length == 0){this.abrirModal("Você não inseriu Utensilios na sua receita!");return;}
     //if(this.equipamentos.length == 0){this.abrirModal("Você não inseriu Equipamentos na sua receita!");return;}
     if(this.ingredientes.length == 0){this.abrirModal("Você não inseriu Ingredientes na sua receita!");return;}
     if(this.passo1.length == 0 || this.passo2.length == 0 || this.passo3.length == 0 || this.passo4.length == 0){this.abrirModal("Os 4 primeiros 'Passos da receita' são obrigatórios!");return;}
      if(this.status != 'Publicado' && this.status != 'Negado'){this.status = "Analise";}
     this.enviar();
   },
   enviar(){
    let json = JSON.stringify(this.estruturaReceita);
      
     this.carregar = true;
     
     
     let dados = new FormData();
     dados.append('receita',json);
     if(this.selectedFile != null){ dados.append('capa',this.selectedFile,this.selectedFile.name); }

      fetch(servidor+'gerenciaReceita/cadastrar',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.carregar = false;
            console.log(data);

            if(data.erro != null){
                this.abrirModal(data.erro);
                return;
            }else{
              if(data.status == 'Rascunho' && data.acao == 'update'){
                if(data.foto != ''){this.foto = data.foto;}
                this.abrirModal('Receita salva com sucesso!');
                return;
              }

              if(data.status == 'Analise' && data.acao == 'update'){
                if(data.foto != ''){this.foto = data.foto;}
                //this.abrirModal('Receita enviada para análise com sucesso! Estaremos analisando e publicando sua receita o mais rápido possivel. Obrigado.');
                this.$store.commit('alterarMensagem','Muito obrigado! Ficamos felizes pela sua participação. Sua receita foi recebida e será agora analisada. Se tudo estiver certo ela será publicada logo, logo.');
                this.$router.push('/compartilhe');
                return;
              }

               if(data.status == 'Analise' && data.acao != 'update'){
                 this.$store.commit('alterarMensagem','Muito obrigado! Ficamos felizes pela sua participação. Sua receita foi recebida e será agora analisada. Se tudo estiver certo ela será publicada logo, logo.');
                this.$router.push('/compartilhe');
                return;
              }

               if(data.status == 'Rascunho' && data.acao != 'update'){
                this.$store.commit('alterarMensagem','Receita salva com sucesso!');
                this.$router.push('/compartilhe');
                return;
              }

              if(data.status == 'Re-enviado' && data.acao == 'update'){
                this.$store.commit('alterarMensagem','Muito obrigado! Ficamos felizes pela sua participação. Sua receita foi recebida e será agora analisada novamente. Se tudo estiver certo ela será publicada logo, logo.');
                this.$router.push('/compartilhe');
                return;
              }

               
               
            }
          });
   },

   edicao(){
      this.carregar = true;
      fetch(servidor+'gerenciaReceita/getReceita/'+this.id)
          .then((response) => response.json())
          .then((data) => {
            this.carregar = false;
            if(data.erro != null){
                this.abrirModal(data.erro);
            }else{
               this.nome = data.receita.RE_NOME;
               this.categoria = data.receita.RE_CATEGORIA;
               this.custo = data.receita.RE_CUSTO;
               this.dificuldade = data.receita.RE_DIFICULDADE;
               this.rendimento = data.receita.RE_RENDIMENTO;
               this.tempo = data.receita.RE_TEMPO;
               this.preparo = data.receita.RE_PREPARO;
               this.dieta = data.receita.RE_DIETA;
               this.passo1 = data.receita.RE_PASSO1;
               this.passo2 = data.receita.RE_PASSO2;
               this.passo3 = data.receita.RE_PASSO3;
               this.passo4 = data.receita.RE_PASSO4;
               this.passo5 = data.receita.RE_PASSO5;
               this.passo6 = data.receita.RE_PASSO6;
               this.sugestao= data.receita.RE_SUGESTAO;
               this.termos = data.receita.RE_TERMOS;
               this.pontos = data.receita.RE_PONTOS;
               this.motivo = data.receita.RE_MOTIVO;
               this.foto = data.receita.RE_FOTO;
               this.acompanhamento = data.receita.RE_ACOMPANHAMENTO;
               this.status = data.receita.RE_STATUS;
               this.acao = 'update';

               data.utensilios.forEach((utensilio) => this.utensilios.push({nome:utensilio.UR_NOME,novo:utensilio.UR_NOVO}));
               data.equipamentos.forEach((equipamento) => this.equipamentos.push({nome:equipamento.ER_NOME,novo:equipamento.ER_NOVO}));
               data.ingredientes.forEach((ingrediente) => this.ingredientes.push(
                 {nome:ingrediente.IR_NOME,novo: ingrediente.IR_NOVO,qtd:ingrediente.IR_QUANTIDADE.toString(),medida:ingrediente.IR_DOMESTICA,obs:ingrediente.IR_OBSERVACAO,posicao:this.ingredientes.length}
               ));
            }
        });
    }
  },
  computed:{
    estruturaReceita(){
      return {
        utensilios:this.utensilios,
        equipamentos:this.equipamentos,
        ingredientes:this.ingredientes,
        nome:this.nome,
        categoria:this.categoria,
        custo:this.custo,
        dificuldade:this.dificuldade,
        rendimento:this.rendimento,
        tempo:this.tempo,
        dieta:this.dieta,
        preparo:this.preparo,
        passo1:this.passo1,
        passo2:this.passo2,
        passo3:this.passo3,
        passo4:this.passo4,
        passo5:this.passo5,
        passo6:this.passo6,
        sugestao:this.sugestao,
        acompanhamento:this.acompanhamento,
        pontos:this.pontos,
        termos:this.termos,
        usuario:this.$store.state.usuario.id,
        permissao:this.$store.state.usuario.permissao,
        status:this.status,
        id:this.id,
        acao:this.acao
      }
    }
    
  },
  mounted(){
   this.getDietas().then((response) => this.dietas = response);

   if(this.$route.params.id != null){
      this.id = this.$route.params.id;
      this.edicao();
    }
  }
}
</script>
